import network from "@/api/network";

export default class Reports {
  static async getReportUsers(group, page, perPage, data) {
    return await network.get(`reports/users/list/promo/group/${group}/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportReportUsers(group, data, config) {
    return await network.get(`reports/users/list/promo/group/${group}/filter/export`, data, config);
  }

  static async getReportMembers(page, perPage, data) {
    return await network.get(`reports/participants/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportReportMembers(data, config) {
    return await network.get("reports/participants/list/filter/export", data, config);
  }

  static async getReportInvite(page, perPage, data) {
    return await network.get(`reports/invites/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportReportInvite(data, config) {
    return await network.get("reports/invites/list/filter/export", data, config);
  }

  static async deleteInvite(id) {
    return await network.delete(`invites/${id}`, null, {});
  }

  static async getReportPrize(page, perPage, data) {
    return await network.get(`reports/prizes/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportReportPrize(data, config) {
    return await network.get("reports/prizes/list/filter/export", data, config);
  }

  static async getReportStatus(page, perPage, data) {
    return await network.get(`reports/deals/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportReportStatus(data, config) {
    return await network.get("reports/deals/list/filter/export", data, config);
  }

  static async getStatusList() {
    return await network.get("deals/statuses/list", null, {});
  }

  static async changeReportStatus(data) {
    return await network.put("deals/report/status", data, {});
  }

  // static async getChangeStatusQueue(data) {
  //   return await network.get("deals/report/tasks/queue/accept/status", data, {});
  // }

  static async getReportData(page, perPage, data) {
    return await network.get(`reports/deals/detail/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportReportData(data, config) {
    return await network.get("reports/deals/detail/list/filter/export", data, config);
  }
}
