import network from "@/api/network";

export default class Deals {
  static async getDeals(page, perPage, data) {
    return await network.get(`deals/curators/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportDeals(data, config) {
    return await network.get("deals/curators/list/filter/export", data, config);
  }

  static async getReports(page, perPage, data) {
    return await network.get(`deals/report/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async changeReport(data) {
    return await network.post("deals/report/update", data, {});
  }

  static async deleteReport(id) {
    return await network.delete(`deals/report/${id}`, null, {});
  }

  static async addReport(data) {
    return await network.post("deals/report/import", data, {});
  }

  // static async сheckReportImport(task_id) {
  //   return await network.get(`deals/report/import/tasks/${task_id}/status`, null, {});
  // }

  static async getQueueStatus(task_id) {
    return await network.get(`queue/${task_id}/status`, null, {});
  }
}
